import React, { useState } from 'react';
import birthday from './birthdayBackground.png';
import wedding from './weddingBackground.png';
import anniversary from './anniversaryBackground.png';
import './App.css';

const App = () => {
  const [rendered, setRendered] = useState('wedding');

  return (
    <div style={{ display: 'flex', height: '100vh', backgroundImage: rendered === 'wedding' ? `url(${wedding})` : rendered === 'birthday' ? `url(${birthday})` : `url(${anniversary})`, backgroundSize: 'cover' }}>
      <div style={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
        <div>
          <h1 style={{ fontFamily: 'GreatVibes', fontSize: 90, marginBottom: 0, textAlign: 'center', color: '#5d5340', fontWeight: 100 }}>Special Occasion Store</h1>
          <h3 style={{ fontFamily: "Montserrat", marginTop: 0, textAlign: 'center', fontWeight: 100, color: '#5d5340' }}>Custom apps for your special <br/> someone</h3>
        </div>
        <a target="_blank" href="https://www.etsy.com" rel='noopener noreferrer'>
          <button className='shop_btn'>SHOP NOW</button>
        </a>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {/* 3 buttons on the right side of the screen that route to /wedding, /birthday, /anniversary */}
          <a target="_blank" href="https://wedding.specialoccasionapp.com" rel='noopener noreferrer'>
            <button onMouseEnter={()=>setRendered('wedding')} className='wedding_btn' style={buttonStyle}>Wedding</button>
          </a>
          <a target="_blank" href="https://birthday.specialoccasionapp.com" rel='noopener noreferrer'>
            <button onMouseEnter={()=>setRendered('birthday')} className='birthday_btn' style={buttonStyle}>Birthday</button>
          </a>
          <a target="_blank" href="https://anniversary.specialoccasionapp.com" rel='noopener noreferrer'>
            <button onMouseEnter={()=>setRendered('anniversary')} className='anniversary_btn' style={buttonStyle}>Anniversary</button>
          </a>
        </div>
      </div>
    </div>
  );
};

const buttonStyle = { 
  marginRight: 10,
  backgroundColor: '#496523',
  borderRadius: 15,
  padding: 10,
  borderWidth: 0,
  width: 180,
  height: 35,
  color: 'white',
  fontFamily: 'Montserrat',
}
export default App;
